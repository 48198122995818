<template>
	<div id="app">
	  <myheader></myheader>
	  <router-view/>
	  <myfooter></myfooter>
	</div>
</template>
<script>
import myheader from '@/components/header.vue'
import myfooter from '@/components/footer.vue'

export default {
	components: {
		myheader, myfooter
	},

	data () {
		return {

		}
	},
	methods: {

	}
}
</script>
<style lang="scss">

</style>
  